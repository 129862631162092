/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// This component renders an item that appears in the desktop
// horizontal header nav menu, and its dropdown submenu when hovered (or clicked) upon
//
// Props:
//    item - the menu item in navmenu object format (see DesktopNavMenu or MenuBuilder)
//    index - index of item within menu.  E.g. index = 3 means this is third item on top menu.
//    length - total # of items on top menu.  Used to compute alignment of dropdown menu.  See computePopperPlacement.
//

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes, { shape, string } from 'prop-types';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import extractMenuAttributes, { extractHoverAttributes, extractDropDownAttributes } from '../../../../../../helpers/contentstack/extractMenuAttributes';

import { getIsBot } from '../../../../../../../state/ducks/App/App-Selectors';
import { actions as tagManagerActions } from '../../../../../../../state/ducks/TagManager';
import { determineCollectionPrefixPath } from '../../../../../../helpers/determineInternationalizationPath';
import { getFeatureFlags } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const styles = (theme) => ({  // eslint-disable-line arrow-parens
    // spacing: 0,
    topNavItem: {
        listStyle: 'none',
        height: '100%',
        margin: 0,
        padding: 0,
        position: 'relative',
    },
    topMenuLink: {
        textDecoration: 'none',
        display: 'inline-block',
        height: '100%',
    },
    topMenuButton: {
        color: '#000000de',
        textTransform: 'uppercase',
        fontSize: '15px',
        letterSpacing: '0',
        padding: '10px 10px',
        borderBottom: '3px solid transparent',
        borderTop: '3px solid transparent',
        borderRadius: '0',
        fontFamily: ['Roboto', 'Noto', 'Arial', 'sans-serif'].join(','),
        fontWeight: 'bold',
        transition: 'none',
        outline: '1px solid transparent',
        height: '100%',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:active': {
            backgroundColor: 'transparent',
        },
    },
    plantsTopMenuLink: {
        textDecoration: 'none',
        fontFamily: 'Hind, sans-serif',
        fontSize: '0.875rem',
        lineHeight: '21px',
        fontWeight: '400',
        letterSpacing: '0.7px',
        opacity: '1',
        fontStyle: 'normal',
        '& button': {
            backgroundColor: 'transparent',
        },
    },
    plantsTopMenuButton: {
        fontFamily: ['"Hind", sans-serif'],
        fontSize: '0.775rem',
        fontWeight: '400',
        letterSpacing: '0.7px',
        lineHeight: '21px',
        backgroundColor: '#f2f2f2ed',
        '@media (max-width: 1264px)': {
            fontSize: '0.675rem',
        },
        textTransform: 'uppercase',
        color: '#000000de',
        padding: '6px 8px 30px 8px',
        '&:focus-visible': {
            outline: 'none',
        },
    },
    alternateNavItem: {
        '& button': {
            border: `1px solid ${theme.palette.text.primary}`,
            borderBottom: 'none',
            borderRadius: '5px 5px 0 0',
        },
    },
    topMenuListPopper: {
        backgroundColor: theme.palette.desktopHeaderMenu.background,
        boxShadow: 'none',
        border: '1px solid #CCC',
        borderRadius: '0',
        zIndex: '100',
        display: 'flex',
    },
    topMenuListPopperIe: {
        backgroundColor: theme.palette.desktopHeaderMenu.background,
        boxShadow: 'none',
        border: '1px solid #CCC',
        borderRadius: '0',
        zIndex: '100',
        display: 'block',
    },
    plantsTopMenuListPopper: {
        backgroundColor: theme.palette.desktopHeaderMenu.background,
        boxShadow: 'none',
        border: '1px solid #CCC',
        borderRadius: '0',
        zIndex: '1085',
        display: 'flex',
        maxWidth: '40%',
        padding: '8px',
    },
    plantsTopMenuListPopperIe: {
        backgroundColor: theme.palette.desktopHeaderMenu.background,
        boxShadow: 'none',
        border: '1px solid #CCC',
        borderRadius: '0',
        zIndex: '1085',
        display: 'block',
    },
    dropdownHeading: {
        color: theme.palette.colorPrimary,
        fontSize: '13px',
        fontWeight: 'bold',
        lineHeight: '20px',
        padding: '3px 20px 3px 20px',
        cursor: 'auto',
        textDecoration: 'none',
        textRendering: 'optimizeLegibility',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
        '&:active': {
            backgroundColor: 'transparent',
            textDecoration: 'none',
        },
        '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'none',
        },
        '&:focus': {
            backgroundColor: 'transparent',
            textDecoration: 'none',
        },
    },
    dropdownHeadingLink: {
        color: theme.palette.colorPrimary,
        fontSize: '13px',
        fontWeight: 'bold',
        lineHeight: '20px',
        padding: '3px 20px 3px 20px',
        textDecoration: 'none',
        textRendering: 'optimizeLegibility',
        whiteSpace: 'break-spaces',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
        '&:active': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
        '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
        '&:focus': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
    },
    plantsDropdownHeadingLink: {
        color: theme.palette.colorPrimary,
        fontSize: '13px',
        fontWeight: 'bold',
        lineHeight: '20px',
        padding: '3px 20px 3px 20px',
        textDecoration: 'none',
        textRendering: 'optimizeLegibility',
        whiteSpace: 'break-spaces',
        zIndex: '1082',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
        '&:active': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
        '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
        '&:focus': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
    },
    dropdownMenuItem: {
        color: theme.palette.desktopHeaderMenu.text,
        fontSize: '13px',
        fontWeight: 'normal',
        lineHeight: '20px',
        letterSpacing: '0',
        padding: '3px 60px 3px 20px',
        textDecoration: 'none',
        textRendering: 'optimizeLegibility',
        whiteSpace: 'normal',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
        fontFamily: ['Roboto', 'sans-serif'].join(','),
        '&:active': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
        '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
        '&:focus': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
    },
    plantsDropdownMenuItem: {
        display: 'flex',
        width: '100%',
        color: theme.palette.desktopHeaderMenu.text,
        fontSize: '0.9375rem',
        fontWeight: 'normal',
        lineHeight: '2.188rem',
        letterSpacing: '0.013rem',
        textDecoration: 'none',
        zIndex: '1082',
        textRendering: 'optimizeLegibility',
        // whiteSpace: 'normal',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
        fontFamily: ['Hind', 'sans-serif'].join(','),
        '&:active': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
        '&:hover': {
            backgroundColor: '#d0d0d0',
            textDecoration: 'none ',
        },
        '&:focus': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
        },
    },
    banner: {
        padding: '5px 15px 0 0',
        margin: '0',
        display: 'inline-block',
        '& a': {
            '& img': {
                width: '200px',
                height: 'auto',
            },
        },
    },
    bannerIe: {
        padding: '5px 15px 0 0',
        margin: '0',
        display: 'inline-block',
        '& a': {
            '& img': {
                width: '200px',
                height: '265px',
            },
        },
    },
    menuList: {
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 10px 20px 0px',
    },
    menuListIe: {
        display: 'block',
    },
    dropDownMainIe: {
        display: 'inline-block',
        verticalAlign: 'top',
        width: 'auto',
    },
    dropDownIe: {
        display: 'inline-block',
        verticalAlign: 'top',
    },
    dropdownPlantsVertical: {
        display: 'block',
    },
    customBackdropLeft: {
        zIndex: 10,
        width: '100vw',
        height: '100vh',
        right: '100%',
        position: 'absolute',
        background: theme.palette.bgTextOverlay1,
    },
    customBackdropRight: {
        right: 'unset',
        left: 0,
    },
    menuIconImage: {
        width: '25px',
        height: '25px',
        padding: '2px 3px',
    },
});

// Supported by Composer and CMS/graphql
// const LINKSTYLE_NORMAL = 'Normal';
// const LINKSTYLE_ALTERNATE = 'Alternate';
// const LINKSTYLE_DIVIDER = 'Divider';
const LINKSTYLE_HEADING = 'Heading';

function isAbsoluteLink(link) {
    return link.indexOf('://') !== -1;
}

const DropDownColumn = ({
    item, column, size, topMenuItemId, classes, ieBrowser, actionName, trackEvent, brand, subMenuLength,
}) => (
    <Grid className={ieBrowser ? classes.dropDownIe : (brand.code === 'PLA' && subMenuLength < 4 && classes.dropdownPlantsVertical)} item={brand.code !== 'PLA'} container={brand.code === 'PLA'} xs={size}>
        {item.submenu
            .filter((i) => i.column === column)            // eslint-disable-line arrow-parens
            .map((columnItem, row) => {
                const key = `${topMenuItemId}_${column}_${row + 1 - 1}`;
                const id = `${topMenuItemId}_${column}_${row}`;

                if (columnItem.linkStyle === LINKSTYLE_HEADING && !columnItem.link) {
                    return (
                        <MenuItem key={key} id={id} className={classes.dropdownHeading} disableRipple>
                            {columnItem.label}
                        </MenuItem>
                    );
                }
                const targetURL = (item?.type?.includes('blog') || item?.type?.includes('page')) ? columnItem.link : determineCollectionPrefixPath(columnItem.link, false);
                // Material UI docs say to do this here as constant instead of inline component={expr ? a : Link}
                const renderLink = isAbsoluteLink(columnItem.link)
                    ? React.forwardRef((props, ref) => <a target="_blank" rel="noreferrer" ref={ref} href={columnItem.link} {...props} />) // eslint-disable-line jsx-a11y/anchor-has-content
                    : React.forwardRef((props, ref) => <Link to={targetURL || columnItem.link} innerRef={ref} target={(targetURL || columnItem.link).indexOf('greenhouse') > -1 ? '_blank' : ''} {...props} aria-label="column-link" aria-labelledby="column-link" />);
                return (
                    <>
                        {brand.code === 'PLA'
                            ? item.column ? (
                                <Grid item xs={12 / item.column}>
                                    <MenuItem
                                        key={key}
                                        id={id}
                                        className={columnItem.linkStyle === LINKSTYLE_HEADING ? classes.plantsDropdownHeadingLink : classes.plantsDropdownMenuItem}
                                        component={renderLink}
                                        onClick={() => trackEvent({
                                            eventName: 'feature_interaction',
                                            eventCategory: columnItem.track_event?.tracking_event_category || 'Global Nav',
                                            eventAction: 'Click',
                                            eventLabel: `${columnItem.track_event?.tracking_event_action || actionName || ''} - ${columnItem.track_event?.tracking_event_label || columnItem.label || ''}`,
                                            trackEventAction: trackEvent,
                                        })}
                                    >
                                        {columnItem.label}
                                    </MenuItem>
                                </Grid>
                            ) : (
                                <MenuItem
                                    key={key}
                                    id={id}
                                    className={columnItem.linkStyle === LINKSTYLE_HEADING ? classes.plantsDropdownHeadingLink : classes.plantsDropdownMenuItem}
                                    component={renderLink}
                                    onClick={() => trackEvent({
                                        eventName: 'feature_interaction',
                                        eventCategory: columnItem.track_event?.tracking_event_category || 'Global Nav',
                                        eventAction: columnItem.track_event?.tracking_event_action || actionName || '',
                                        eventLabel: columnItem.track_event?.tracking_event_label || columnItem.label || '',
                                        trackEventAction: trackEvent,
                                    })}
                                >
                                    {columnItem.label}
                                </MenuItem>
                            )
                            : (
                                <MenuItem
                                    key={key}
                                    id={id}
                                    className={columnItem.linkStyle === LINKSTYLE_HEADING ? classes.dropdownHeadingLink : classes.dropdownMenuItem}
                                    component={renderLink}
                                    onClick={() => trackEvent({
                                        eventName: 'feature_interaction',
                                        eventCategory: columnItem.track_event?.tracking_event_category || 'Global Nav',
                                        eventAction: columnItem.track_event?.tracking_event_action || actionName || '',
                                        eventLabel: columnItem.track_event?.tracking_event_label || columnItem.label || '',
                                        trackEventAction: trackEvent,
                                    })}
                                >
                                    {columnItem.label}
                                </MenuItem>
                            )}
                    </>
                );
            })}
    </Grid>
);

DropDownColumn.propTypes = {
    brand: shape({
        code: string.isRequired,
    }).isRequired,
    item: PropTypes.object.isRequired,
    column: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    topMenuItemId: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    ieBrowser: PropTypes.bool.isRequired,
    actionName: PropTypes.string.isRequired,
    trackEvent: PropTypes.func.isRequired,
    subMenuLength: PropTypes.number.isRequired,
};

export class DesktopTopNavMenuItem extends Component {
    constructor(props) {
        super(props);
        const { index, item } = this.props;
        this.rootId = `${item.label.replace(/ /g, '')}`;
        this.topMenuItemId = `${this.rootId}_${index}`;

        this.state = {
            open: false,
            ieBrowser: false,
        };
        this.focused = false;
        this.menuItemRef = createRef();
    }

    componentDidMount() {
        if (typeof document !== 'undefined') {
            const ieBrowser = /* @cc_on!@ */false || !!document.documentMode;
            this.setState({ ieBrowser });
        }
    }

    handleToggle = () => {
        this.setState((state) => ({ open: !state.open }));
    };

    handleOpen = () => {
        if (typeof document !== 'undefined') {
            this.focused = true;
            setTimeout(() => {
                if (this.focused) {
                    document.getElementById(this.topMenuItemId).focus();
                    this.setState({ open: true });
                }
            }, 300);
        }
    };

    handleClose = () => {
        this.focused = false;
        this.setState({ open: false });
    };

    handleButtonKeyDown = (event) => {
        const { open } = this.state;

        switch (event.which || event.keyCode) {
            case 38: // up-arrow
                event.preventDefault();
                event.stopPropagation();
                if (open) {
                    this.setState({ open: false });
                }
                break;
            case 40: // down-arrow
                event.preventDefault();
                event.stopPropagation();
                if (!open) {
                    this.handleOpen();
                } else {
                    const el = (typeof document !== 'undefined') ? document.getElementById(`${this.topMenuItemId}_1_1`) : null;
                    if (el) {
                        el.focus();
                    }
                }
                break;
            case 37:
                {
                    // left-arrow
                    event.preventDefault();
                    event.stopPropagation();
                    const { index } = this.props;
                    if (index > 1 && typeof document !== 'undefined') {
                        document.getElementById(`${this.rootId}_${index - 1}`).focus();
                    }
                }
                break;
            case 39:
                {
                    // right-arrow
                    event.preventDefault();
                    event.stopPropagation();
                    const { index } = this.props;
                    const nextMenu = (typeof document !== 'undefined') ? document.getElementById(`${this.rootId}_${index + 1}`) : null;
                    if (nextMenu) {
                        nextMenu.focus();
                    }
                }
                break;

            default:
        }
    };

    // TODO - replace with something that measures popper and positions accordingly.
    // Until then, this simple algorithm works most of the time.
    computePopperPlacement = (index, length) => {
        //  Item on first third of screen
        //   +----+
        //   |item|
        //   +-----------+
        //   | dropdown  |
        if (index <= length / 3) {
            return 'bottom-start';
        }

        //  Item on last third of screen
        //          +----+
        //          |item|
        //   +-----------+
        //   | dropdown  |
        if (index >= (2 * length) / 3) {
            return 'bottom-end';
        }

        //  Item on middle third of screen
        //      +----+
        //      |item|
        //   +----------+
        //   | dropdown |
        return 'bottom';
    };

    render() {
        const {
            classes, item, index, length, attributes, trackEvent, isBot, brand, subMenuLength,
        } = this.props;
        if (!item) {
            return null;
        }
        const { open, ieBrowser } = this.state;
        const dropdownId = `${this.rootId}_${index}`;
        const itemStyleOverrides = extractMenuAttributes(attributes);
        const alternateItemStyleOverrides = {
            height: item.additionalHeight > 0 ? `calc(100% + ${item.additionalHeight}px)` : '100%',
            top: item.additionalHeight > 0 ? `-${item.additionalHeight}px` : '0',
        };
        // Add the menuLink Icon Image
        const MenuLinkIcon = item.icon ? <img src={item.icon} alt="MenuIcon" className={classes.menuIconImage} /> : null;
        // The height is only set to main nav, setting height on this div in px does not render properly
        if (itemStyleOverrides.height) {
            itemStyleOverrides.height = '100%';
        }
        if (item.color) {
            itemStyleOverrides.color = item.color;
        }
        if (item.backgroundColor) {
            itemStyleOverrides.backgroundColor = item.backgroundColor;
        } else {
            itemStyleOverrides.backgroundColor = 'transparent';
        }
        let hoverAttributes = extractHoverAttributes(attributes);
        const dropDownAttributes = extractDropDownAttributes(attributes);
        // TODO: This is temporary for the 18F and 18B as the contentstack is not updated with latest fields.
        if (!Object.keys(hoverAttributes).length > 0) {
            hoverAttributes = {
                ...itemStyleOverrides,
                borderBottom: '2px solid rgb(0, 0, 0)',
            };
        } else {
            Object.keys(itemStyleOverrides).forEach((key) => {
                if (!hoverAttributes[key]) {
                    hoverAttributes[key] = itemStyleOverrides[key];
                }
            });
        }
        if (item?.main_link) {
            let linkHref = '';
            if (getFeatureFlags['is-internationalization-enabled']) {
                linkHref = brand?.code === '08F' ? determineCollectionPrefixPath(item.main_link.href, true) : determineCollectionPrefixPath(item.main_link.href, false);
                item.main_url = {
                    href: linkHref,
                };
            } else {
                if (item?.type?.includes('blog') || item?.type?.includes('page')) {
                    linkHref = item.main_link.href;
                } else {
                    linkHref = determineCollectionPrefixPath(item.main_link.href, false);
                }
                item.main_url = {
                    href: linkHref,
                };
            }
        }
        // # of columns in submenu = column # from last submenu item
        const numberOfColumns = item.submenu && item.submenu.length ? item.submenu[item.submenu.length - 1].column : 0;
        let containerXS = 12;
        let columnXS = 6;
        if (numberOfColumns < 5) {
            columnXS = 12 / numberOfColumns;
        } else {
            columnXS = 2;
            containerXS = numberOfColumns * columnXS;
        }

        const columnRange = new Array(numberOfColumns).fill(undefined).map((_, i) => i + 1); // e.g. if 3 columns, range = [1, 2, 3]
        const { menu_image } = item;

        const menuListStyle = {};
        if (menu_image && menu_image.image !== null && attributes.background_image_fade) {
            menuListStyle.background = `linear-gradient(to right, rgba(245, 244, 246, 1) 0%, rgba(245, 244, 246, 1) 44%, rgba(245, 244, 246, 0) 90%, rgba(245, 244, 246, 0) 100%), #f5f4f6 url(${menu_image.image.url}) no-repeat bottom right`;
            menuListStyle.paddingRight = 200;
            menuListStyle.backgroundSize = 'cover';
            menuListStyle.minHeight = attributes.dropdown_minimum_height ? attributes.dropdown_minimum_height : 0;
        }

        const getStyleObject = () => {
            if (item.style && item.style.length) {
                return { color: item.style[0].font_color.font_color.color };
            }
            return {};
        };

        const navMenuItemDropdown = () => (
            <MenuList id={dropdownId} dense disablePadding className={ieBrowser ? classes.menuListIe : classes.menuList} style={menuListStyle}>
                <Grid className={ieBrowser ? classes.dropDownMainIe : ''} container item xs={containerXS}>
                    {columnRange.map((column) => (
                        <DropDownColumn
                            brand={brand}
                            key={`${this.topMenuItemId}_${column}`}
                            item={item}
                            column={column}
                            size={isBot ? 12 : columnXS}
                            topMenuItemId={this.topMenuItemId}
                            classes={classes}
                            ieBrowser={ieBrowser}
                            trackEvent={trackEvent}
                            actionName={item.label}
                            subMenuLength={subMenuLength}
                        />
                    ))}
                </Grid>
                {!attributes.background_image_fade && menu_image && menu_image.image !== null && menu_image.link.title !== null ? (
                    <Grid className={ieBrowser ? classes.bannerIe : classes.banner}>
                        <Link to={menu_image.link.href} title={menu_image.link.title} aria-label="menu-title" aria-labelledby="menu-title">
                            <img src={menu_image.image.url} alt={menu_image.link.title} />
                        </Link>
                    </Grid>
                ) : null}

            </MenuList>
        );

        const navMenuLabelAndDropDown = () => (
            <>
                {/**
                  * Top Nav Button: button displayed on top nav
                  * on hover, activates menu popover
                */}
                {
                    (item?.main_url?.href || item.main_link?.href || item.link) === '/'
                    || (item?.main_url?.href || item.main_link?.href || item.link) === ''
                        ? (
                            <Button
                                id={this.topMenuItemId}
                                data-test="top-menu-item"
                                name="topMenuItem"
                                tabIndex="0"
                                className={brand.code === 'PLA' ? classes.plantsTopMenuButton : classes.topMenuButton}
                                style={open ? hoverAttributes : itemStyleOverrides}
                                ref={this.menuItemRef}
                                aria-owns={open ? this.topMenuItemId : undefined}
                                aria-haspopup="true"
                                onClick={this.handleToggle}
                                onKeyDown={this.handleButtonKeyDown}
                                disableRipple
                            >
                                {MenuLinkIcon}{item.label}
                            </Button>
                        )
                        : (
                            <Link
                                aria-label={item.label}
                                aria-labelledby="Menu item"
                                onClick={() => trackEvent({
                                    eventCategory: item.track_event?.tracking_event_category || 'Global Nav',
                                    eventAction: item.track_event?.tracking_event_action || item.label || '',
                                    eventLabel: item.track_event?.tracking_event_label || item.label || '',
                                    eventName: 'feature_interaction', // Requird for GA4 feature_click
                                })}
                                className={brand.code === 'PLA' ? classes.plantsTopMenuLink : classes.topMenuLink}
                                to={item?.main_url?.href || item.main_link?.href || item.link || ''}
                                target={(item?.main_url?.href || item.main_link?.href || item.link || '').indexOf('greenhouse') > -1 ? '_blank' : ''}
                            >
                                <div
                                    id={this.topMenuItemId}
                                    data-test="top-menu-item"
                                    name="topMenuItem"
                                    tabIndex="0"
                                    className={brand.code === 'PLA' ? classes.plantsTopMenuButton : classes.topMenuButton}
                                    style={open ? { ...hoverAttributes, ...getStyleObject() } : { ...itemStyleOverrides, ...getStyleObject() }}
                                    ref={this.menuItemRef}
                                    aria-owns={open ? this.topMenuItemId : undefined}
                                    aria-haspopup="true"
                                    aria-label="Menu Item"
                                    aria-labelledby={item.label}
                                    onClick={this.handleToggle}
                                    onKeyDown={this.handleButtonKeyDown}
                                >
                                    {MenuLinkIcon}{item.label}
                                </div>
                            </Link>
                        )
                }

                {/**
                  * Menu Popover: activates on button hover
                  * shows popover only if submenu exists or an image exists (e.g 18f community tab)
                */}
                { open && (item.submenu?.length || item.menu_image?.image?.url) ? (
                    <>
                        {brand.code === 'PLA' ? (
                            <Popper
                                className={ieBrowser ? classes.plantsTopMenuListPopperIe : classes.plantsTopMenuListPopper}
                                placement={this.computePopperPlacement(index, length)}
                                open={open}
                                style={dropDownAttributes}
                                anchorEl={this.menuItemRef.current}
                                disablePortal={false}
                                onMouseEnter={this.handleOpen}
                                onMouseLeave={this.handleClose}
                                modifiers={{
                                    flip: {
                                        enabled: false,
                                    },
                                    preventOverflow: {
                                        enabled: true,
                                        boundariesElement: 'window',
                                    },
                                }}
                            >
                                <ClickAwayListener style={{ background: '#000' }} onClickAway={this.handleClose}>
                                    {navMenuItemDropdown()}
                                </ClickAwayListener>
                            </Popper>
                        )
                            : (
                                <>
                                    <div className={classes.customBackdropLeft} />
                                    <div className={`${classes.customBackdropLeft} ${classes.customBackdropRight}`} />
                                    <Popper
                                        className={ieBrowser ? classes.topMenuListPopperIe : classes.topMenuListPopper}
                                        placement={this.computePopperPlacement(index, length)}
                                        open={open}
                                        style={dropDownAttributes}
                                        anchorEl={this.menuItemRef.current}
                                        disablePortal={false}
                                        onMouseEnter={this.handleOpen}
                                        onMouseLeave={this.handleClose}
                                        modifiers={{
                                            flip: {
                                                enabled: false,
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                boundariesElement: 'window',
                                            },
                                        }}
                                    >
                                        <ClickAwayListener style={{ background: '#000' }} onClickAway={this.handleClose}>
                                            {navMenuItemDropdown()}
                                        </ClickAwayListener>
                                    </Popper>
                                </>
                            )}
                    </>
                ) : null}
            </>
        );

        // Also tried to trigger menu with onFocus/onBlur for better ADA, but it would capture link clicks so had to take it out -- Seth
        let response = (
            <>
                {(item.main_link && item.main_link.href) || item.link ? (
                    <li
                        className={`${classes.topNavItem} ${item.linkStyle === 'Alternate' && brand?.code === 'HD' ? classes.alternateNavItem : ''} `}
                        style={alternateItemStyleOverrides}
                        onMouseEnter={this.handleOpen}
                        onMouseLeave={this.handleClose}
                        data-testid={item.label}
                    >
                        {navMenuLabelAndDropDown()}
                    </li>
                ) : (
                    <li className={classes.topNavItem} onMouseEnter={this.handleOpen} onMouseLeave={this.handleClose}>
                        {navMenuLabelAndDropDown()}
                    </li>
                )}
            </>
        );

        if (isBot) {
            response = (
                <li>
                    <Link to={item?.main_link?.href || ''} className={classes.topMenuLink} onMouseEnter={this.handleOpen} onMouseLeave={this.handleClose} aria-label={item.label} aria-labelledby="item-label">
                        <Button
                            id={this.topMenuItemId}
                            data-test="top-menu-item"
                            name="topMenuItem"
                            tabIndex="0"
                            className={classes.topMenuButton}
                            style={itemStyleOverrides}
                            ref={this.menuItemRef.current}
                            aria-owns={open ? dropdownId : undefined}
                            aria-haspopup="true"
                            onClick={this.handleToggle}
                            onKeyDown={this.handleButtonKeyDown}
                            disableRipple
                        >
                            {item.label}
                        </Button>
                    </Link>
                    {navMenuItemDropdown()}
                </li>
            );
        }
        return response;
    }
}

const mapStateToProps = (state) => ({
    isBot: getIsBot(state),
    featureFlags: getFeatureFlags(state),
});

const mapDispatchToProps = {
    trackEvent: tagManagerActions.trackEvent,
};

DesktopTopNavMenuItem.propTypes = {
    attributes: PropTypes.object,
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    trackEvent: PropTypes.func.isRequired,
    isBot: PropTypes.bool,
    brand: shape({
        code: string,
    }),
    subMenuLength: PropTypes.number.isRequired,
};

DesktopTopNavMenuItem.defaultProps = {
    attributes: PropTypes.object,
    isBot: false,
    brand: {
        code: '',
    },
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DesktopTopNavMenuItem));
